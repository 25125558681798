<template>
  <div class="page_bg">
    <!-- 顶部 card-->
    <div class="bg_top_linear-gradient" style="padding-bottom:2rem">
      <!-- 顶部返回 -->
      <div class="back_top_name flex-r-s-c" style="padding-left:0.3rem">
        <van-icon name="arrow-left" @click="backPath" />
        <div class="top_name flex-c-s-s" style="margin-left:0.3rem">
          <div class="order_del_status">{{ ['待处理','已通过','已拒绝','已完成','已取消'][orderInfo.status] }}</div>
          <div class="order_del_tip" style="font-size: 0.32rem;">
            退款原因：{{ orderInfo.refundWhy }}
          </div>
        </div>
        <div class="top_icon" />
      </div>
      <!-- 退款的step -->
      <div v-if="orderInfo.status <4" class="order_address_box "
           style="top: 1.68rem;min-height: 2.2rem;position: initial;"
      >
        <template v-if="orderInfo.status === 0">
          <!-- 4步 -->
          <div v-if="orderInfo.refundType === 'RETURN_PRODUCT'" class="return_step ">
            <van-row>
              <van-col span="1" />
              <van-col span="5">
                <div class="step_step flex-r-c-c">
                  <div style="width:30%" />
                  <van-icon :name="step >= 1?'checked':'more-o'" size="0.56rem"
                            :color="step >= 1?'#0767AB':'#DADADA'"
                  />
                  <div :class="step >= 1?'blank':'blank-o'" />
                </div>
                <div class="step_msg flex-c-s-s">
                  <div style="width:30%" />
                  <div>
                    <div class="step_title">{{ orderInfo.progress[0].progressName }}</div>
                    <div class="step_time">{{ orderInfo.progress[0].createTime }}</div>
                  </div>
                </div>
              </van-col>
              <van-col span="6">
                <div class="step_step flex-r-c-c">
                  <div :class="step >= 2?'blank':'blank-o'" />
                  <van-icon :name="step >= 2?'checked':'more-o'" size="0.56rem"
                            :color="step >= 2?'#0767AB':'#DADADA'"
                  />
                  <div :class="step >= 2?'blank':'blank-o'" />
                </div>
                <div class="step_msg flex-r-c-c">
                  <div />
                  <div>
                    <div :class="step >= 2?'step_title':'step_title-o'">{{ orderInfo.progress[1].progressName }}</div>
                    <div class="step_time">{{ orderInfo.progress[1].createTime }}</div>
                  </div>
                </div>
              </van-col>
              <van-col span="6">
                <div class="step_step flex-r-c-c">
                  <div :class="step >= 3?'blank':'blank-o'" />
                  <van-icon :name="step >= 3?'checked':'more-o'" size="0.56rem"
                            :color="step >= 3?'#0767AB':'#DADADA'"
                  />
                  <div :class="step >= 3?'blank':'blank-o'" />
                </div>
                <div class="step_msg flex-r-c-c">
                  <div />
                  <div>
                    <div :class="step >= 3?'step_title':'step_title-o'">
                      {{ orderInfo.progress[2].progressName || '寄回商品' }}
                    </div>
                    <div class="step_time">{{ orderInfo.progress[2].createTime || '' }}</div>
                  </div>
                </div>
              </van-col>
              <van-col span="5">
                <div class="step_step flex-r-c-c">
                  <div :class="step >= 4?'blank':'blank-o'" />
                  <van-icon :name="step >= 4?'checked':'more-o'" size="0.56rem"
                            :color="step >= 4?'#0767AB':'#DADADA'"
                  />
                  <div style="width:30%" />
                </div>
                <div class="step_msg flex-r-c-c">
                  <div style="width:30%" />
                  <div>
                    <div :class="step >= 4?'step_title':'step_title-o'">
                      {{ orderInfo.progress[3].progressName || '退货成功' }}
                    </div>
                    <div class="step_time">{{ orderInfo.progress[3].createTime || '' }}</div>
                  </div>
                </div>
              </van-col>
              <van-col span="1" />
            </van-row>
          </div>
          <!-- 3步 -->
          <div v-else class="return_step ">
            <van-row>
              <van-col span="1" />
              <van-col span="7">
                <div class="step_step flex-r-c-c">
                  <div style="width:30%" />
                  <van-icon :name="step >= 1?'checked':'more-o'" size="0.56rem"
                            :color="step >= 1?'#0767AB':'#DADADA'"
                  />
                  <div :class="step >= 1?'blank':'blank-o'" />
                </div>
                <div class="step_msg flex-c-s-s">
                  <div style="width:30%" />
                  <div>
                    <div class="step_title">{{ orderInfo.progress[0].progressName }}</div>
                    <div class="step_time">{{ orderInfo.progress[0].createTime }}</div>
                  </div>
                </div>
              </van-col>
              <van-col span="8">
                <div class="step_step flex-r-c-c">
                  <div :class="step >= 2?'blank':'blank-o'" />
                  <van-icon :name="step >= 2?'checked':'more-o'" size="0.56rem"
                            :color="step >= 2?'#0767AB':'#DADADA'"
                  />
                  <div :class="step >= 2?'blank':'blank-o'" />
                </div>
                <div class="step_msg flex-r-c-c">
                  <div />
                  <div>
                    <div :class="step >= 2?'step_title':'step_title-o'">{{ orderInfo.progress[1].progressName }}</div>
                    <div class="step_time">{{ orderInfo.progress[1].createTime }}</div>
                  </div>
                </div>
              </van-col>
              <van-col span="7">
                <div class="step_step flex-r-c-c">
                  <div :class="step >= 3?'blank':'blank-o'" />
                  <van-icon :name="step >= 3?'checked':'more-o'" size="0.56rem"
                            :color="step >= 3?'#0767AB':'#DADADA'"
                  />
                  <div style="width:30%" />
                </div>
                <div class="step_msg flex-r-c-c">
                  <div style="width:30%" />
                  <div>
                    <div :class="step >= 3?'step_title':'step_title-o'">
                      {{ orderInfo.progress[2].progressName|| '退货成功' }}
                    </div>
                    <div class="step_time">{{ orderInfo.progress[2].createTime|| '' }}</div>
                  </div>
                </div>
              </van-col>
              <van-col span="1" />
            </van-row>
          </div>
        </template>
        <template v-else>
          <div class="return_step ">
            <van-row>
              <van-col span="1" />
              <van-col span="7">
                <div class="step_step flex-r-c-c">
                  <div style="width:30%" />
                  <van-icon :name="step >= 1?'checked':'more-o'" size="0.56rem"
                            :color="step >= 1?'#0767AB':'#DADADA'"
                  />
                  <div :class="step >= 1?'blank':'blank-o'" />
                </div>
                <div class="step_msg flex-c-s-s">
                  <div style="width:30%" />
                  <div>
                    <div class="step_title">{{ orderInfo.progress[0].progressName }}</div>
                    <div class="step_time">{{ orderInfo.progress[0].createTime }}</div>
                  </div>
                </div>
              </van-col>
              <van-col span="8">
                <div class="step_step flex-r-c-c">
                  <div :class="step >= 2?'blank':'blank-o'" />
                  <van-icon :name="step >= 2?'checked':'more-o'" size="0.56rem"
                            :color="step >= 2?'#0767AB':'#DADADA'"
                  />
                  <div :class="step >= 2?'blank':'blank-o'" />
                </div>
                <div class="step_msg flex-r-c-c">
                  <div />
                  <div>
                    <div :class="step >= 2?'step_title':'step_title-o'">{{ orderInfo.progress[1].progressName }}</div>
                    <div class="step_time">{{ orderInfo.progress[1].createTime }}</div>
                  </div>
                </div>
              </van-col>
              <van-col span="7">
                <div class="step_step flex-r-c-c">
                  <div :class="step >= 3?'blank':'blank-o'" />
                  <van-icon :name="step >= 3?'checked':'more-o'" size="0.56rem"
                            :color="step >= 3?'#0767AB':'#DADADA'"
                  />
                  <div style="width:30%" />
                </div>
                <div class="step_msg flex-r-c-c">
                  <div style="width:30%" />
                  <div>
                    <div :class="step >= 3?'step_title':'step_title-o'">
                      {{ orderInfo.progress[2].progressName|| '退货成功' }}
                    </div>
                    <div class="step_time">{{ orderInfo.progress[2].createTime|| '' }}</div>
                  </div>
                </div>
              </van-col>
              <van-col span="1" />
            </van-row>
          </div>
        </template>
        <!-- <div class="order_address_msg flex-c-s-c"
             style="text-align: left;padding-left:0.34rem;padding-top:0.34rem;padding-bottom:0.34rem;width:90%"
        >
          <div class="address_msg_name">您已成功发起退货申请，请耐心等待商家处理 </div>
          <div class="address_msg_addres van-multi-ellipsis--l2" style="margin-top:0.21rem">
            商家同意，系统将退款给您；
          </div>
          <div class="address_msg_addres van-multi-ellipsis--l2" style="margin-top:0.05rem">
            如果商家拒绝，请联系商家客服进行处理。
          </div>
        </div> -->
        <!-- 退款地址 -->
        <div v-if="orderInfo.userUpdate === '1'" class="order_address_msg flex-c-s-s"
             style="text-align: left;padding-left:0.34rem;padding-top:0.34rem;padding-bottom:0.34rem;width:90%"
        >
          <div class="address_msg_name">您已成功发起退货申请，请耐心等待商家处理 </div>
          <div class="address_msg_addres van-multi-ellipsis--l2" style="margin-top:0.21rem">
            地址：江苏省南京市江宁区秣陵街道爱陵路20号众诚物流2栋2楼
          </div>
          <div class="address_msg_addres van-multi-ellipsis--l2 flex-r-sb-c" style="margin-top:0.05rem;width:8.6rem;height:1.1rem">
            <div>
              收件人：商城退货组
              <br>
              电话：15996371979
            </div>
            <div style="margin-right:0.5rem;margin-top:0.2rem">
              <van-button id="quit_address_btn" style="width:2rem" plain hairline round size="mini" color="#0767AB"
                          type="info" @click="copyAddress"
              >复制地址</van-button>
            </div>

          </div>
        </div>
      </div>
      <div v-else class="order_address_box " style="top: 1.68rem;min-height: 1.2rem;position: initial;">
        <div class="order_address_msg flex-c-s-c"
             style="text-align: left;padding-left:0.34rem;padding-top:0.34rem;padding-bottom:0.34rem;width:90%"
        >
          <div class="address_msg_name">您已取消退款 </div>
          <div class="address_msg_addres van-multi-ellipsis--l2" style="margin-top:0.21rem">
            如要继续退款，可在订单详情中再次申请
          </div>
        </div>
      </div>
      <!-- 退款总额 -->
      <div class="order_check_box order_payway_box flex-r-sb-c"
           style="margin-top:0.23rem;padding:0.19rem 0;margin-bottom:0;"
      >
        <div class="check_title" style="line-height: 1rem;padding-top:0">退款总额</div>
        <div class="check_money" style="line-height: 1rem;font-size: 0.4rem;color: #ED0D0D;padding-right:0.5rem">
          ￥{{ orderInfo.refundAmount }}</div>
      </div>
      <!-- 物流填写 -->
      <div v-if="orderInfo.userUpdate === '1' & orderInfo.status === 0" class="return_case_box" style="border-radius: 0.27rem;padding-left:0.37rem;margin-left:0.23rem">
        <div class="case_box_title" style="">填写物流信息</div>
        <van-cell is-link :value="quitList.name" @click="show = true">
          <template #title>
            <span class="custom-title" style="font-weight:400">选择物流<span style="color:#ED0D0D;">*</span></span>
          </template>
        </van-cell>
        <van-cell>
          <template #title>
            <span class="custom-title" style="font-weight:400">物流单号<span style="color:#ED0D0D;">*</span></span>
          </template>
          <van-field v-model="quitList.wuliuexpressNo" placeholder="请输入物流单号" style="padding:0;"
                     class="field-text-right"
          />
        </van-cell>
      </div>
      <!-- 商品区域 -->
      <div class="order_pro_box" style="margin-top:0.23rem;padding-bottom:1rem;">
        <div class="cart_company" style="margin-bottom:0;margin-top:0;width:9rem;padding-bottom:0.1rem">
          <div class="company_name flex-r-s-c" style="padding-left:0.1rem;">
            退款商品
          </div>
          <div class="company_pro_list">
            <div v-for="(pro_item,pro_index) in orderInfo.detailProductRes" :key="pro_index" class="flex-r-s-c">
              <van-swipe-cell>
                <proCard06 :info="pro_item" />
                <!-- 优惠信息 -->
                <!-- <div v-if="pro_item.resActicityDtos" class="pro_discount_box">
                  <div class="pro_discount_items">
                    <div v-for="(discounts,discounts_index) in pro_item.resActicityDtos" :key="discounts_index"
                         class="pro_discount_item">
                      <span class="discounts_tag">{{ discounts.actionName }}：</span>
                      <span v-for="(discount,discount_index) in discounts.actions" :key="discount_index"
                            class="discounts_tag">
                        {{ discount }}
                      </span>
                    </div>
                  </div>
                </div> -->
              </van-swipe-cell>
            </div>
          </div>
          <van-cell-group>
            <van-field v-model="orderInfo.remark" label="备注:" placeholder="" readonly label-width="1rem" />
          </van-cell-group>
        </div>
      </div>
      <!-- 退款编号 -->
      <div class="order_check_box order_payway_box flex-r-sb-c" style="margin-top:0.23rem;padding:0.19rem 0;margin-bottom:1rem ">
        <div class="check_title" style="line-height: 1rem;padding-top:0">退款编号</div>
        <div class="check_money" style="line-height: 1rem;font-size: 0.4rem;color: #333333;padding-right:0.5rem">
          {{ orderInfo.refundNo }}</div>
      </div>
      <div class="x" style="height: 1rem;width: 100%;" />
    </div>
    <!-- 物流 -->
    <van-action-sheet v-model="show" :actions="actions" cancel-text="取消" close-on-click-action @select="onSelect"
                      @cancel="onCancel"
    />
    <!-- 底部bar -->
    <orderReturnDel ref="orderReturnSub" :info="orderInfo" @action="action" />
  </div>
</template>

<script>
import Clipboard from 'clipboard'
import './index.scss'
import './../cart/index.scss'
import orderReturnDel from '@/components/bar/order_return_del'
import { wuliuList } from '@/utils/constants'
import { refund_detail, refund_cancelRefund, refund_udpate } from '@/api/order'
import proCard06 from '@/components/pro_card_06'
export default {
  name: 'ReturnStatus',
  components: { proCard06, orderReturnDel },
  data() {
    return {
      ischangeAddress: false,
      refundId: '',
      orderInfo: { progress: [{}, {}, {}, {}] },
      activeList: [],
      step: null,
      time: 60 * 1000 * 2880,
      quitList: {},
      show: false,
      actions: wuliuList

    }
  },
  created() {
    if (window.name === '') {
      console.log('首次被加载')
      window.name = 'returnStatus' // 在首次进入页面时我们可以给window.name设置一个固定值
    } else if (window.name === 'isReload') {
      sessionStorage.setItem('jump', true)
    }
    this.refundId = this.$route.query.refundId
    this.getDetail()
  },
  methods: {
    backPath() { window.history.back() },
    getDetail() {
      // 订单信息
      refund_detail(this.refundId).then(res => {
        const parms1 = { active: 1, createTime: '', progressName: '发起成功' }
        const parms2 = { active: 0, createTime: '', progressName: '退回商品' }
        const parms3 = { active: 0, createTime: '', progressName: '退款成功' }
        if (res.data.status === 0) {
          if (res.data.refundType === 'RETURN_PRODUCT') {
            if (res.data.progress.length === 2) {
              res.data.progress.push(parms2)
              res.data.progress.push(parms3)
              this.step = 2
            } else if (res.data.progress.length === 3) {
              if (res.data.progress.progressName === '退款驳回') {
                res.data.progress.push(parms3)
                this.step = 3
              } else {
                res.data.progress.push(parms3)
                this.step = 3
              }
            }
          } else {
            if (res.data.progress.length === 2) {
              res.data.progress.push(parms3)
              this.step = 2
            } else if (res.data.progress.length === 1) {
              res.data.progress.unshift(parms1)
              res.data.progress.push(parms3)
              this.step = 2
            }
          }
        } else { this.step = 3 }

        this.orderInfo = res.data
      })
    },
    // 底部行为
    action(e) {
      console.log(e)
      if (e === 'cancerReturn') {
        refund_cancelRefund(this.orderInfo.refundId).then(res => {
          this.$toast({ message: '已取消', duration: 1 * 1000 })
          this.backPath()
        })
      }
      if (e === 'subWuliu') {
        const parms = {
          refundId: this.refundId,
          expressCode: this.quitList.id,
          expressName: this.quitList.name,
          expressNo: this.quitList.wuliuexpressNo
        }
        console.log(parms)
        refund_udpate(parms).then(res => {
          this.$toast({ message: '已提交', duration: 1 * 1000 })
        })
      }
    },
    // 修改地址
    changeAddress(addressId) {
      this.$router.push('/user/addressList?aId=' + addressId)
    },
    // 复制地址
    copyAddress() {
      var that = this
      var data = '商城退货组 15996371979 南京市江宁区秣陵街道爱陵路20号众诚物流2栋2楼'
      const clipboard = new Clipboard('#quit_address_btn', {
        text: function () {
          return data
        }
      })
      clipboard.on('success', function (e) {
        e.clearSelection() // 清除选中的文字的选择状态
        that.$toast({ message: '复制成功', duration: 1 * 1000 })
      })

      clipboard.on('error', function (e) {
        console.error('Action:', e.action)
        console.error('Trigger:', e.trigger)
      })
    },
    // 选择物流
    onSelect(item) {
      this.show = false
      this.$set(this.quitList, 'id', item.id)
      this.$set(this.quitList, 'name', item.name)
      console.log('item', item)
    },
    onCancel() { }

  }
}
</script>

<style>
.van-count-down {
  color: #fff;
}
body {
  background: #f7f7f7;
}
.field-text-right .van-field__control {
  text-align: right;
}
</style>
